export const GOOGLE_SIGN_IN_START = "GOOGLE_SIGN_IN_START";
export const EMAIL_SIGN_IN_START = "EMAIL_SIGN_IN_START";
export const SIGN_IN_FAILURE = "SIGN_IN_FAILURE";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const CHECK_USER_SESSION = "CHECK_USER_SESSION";
export const SIGN_OUT_START = "SIGN_OUT_START";
export const SIGN_OUT_FAILURE = "SIGN_OUT_FAILURE";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";

export const SIGN_UP_START = "SIGN_UP_START";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_FAILURE = "SIGN_UP_FAILURE";

export const TOGGLE_CART_HIDDEN = "TOGGLE_CART_HIDDEN";
export const ADD_ITEM = "ADD_ITEM";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const CLEAR_ITEM_FROM_CART = "CLEAR_ITEM_FROM_CART";
export const CLEAR_CART = "CLEAR_CART";

export const FETCH_COLLECTIONS_START = "FETCH_COLLECTIONS_START";
export const FETCH_COLLECTIONS_SUCCESS = "FETCH_COLLECTIONS_SUCCESS";
export const FETCH_COLLECTIONS_FAILURE = "FETCH_COLLECTIONS_FAILURE";
